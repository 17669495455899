<template>
  <!-- 公交出行配置 -->
  <div class="bus-travel-config-page">
    <div class="bus-travel-config-title">
      <span>公交出行配置列表</span>
      <el-button type="primary" size="small" @click="addBtnFun">
        <span style="margin-right: 4px">+</span>
        <span>新增配置</span>
      </el-button>
    </div>
    <Table
      :col-class-type="true"
      :drag-onoff="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      operation-width="200"
      @dragFinsh="dragFinsh"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow, '编辑')"
              >编辑</el-button
            >
          </template>
          <template>
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow, '查看')"
              >查看</el-button
            >
          </template>
          <template>
            <el-button
              v-if="
                listFind('绑定车辆') &&
                scope.scopeRow.cityBusStatus != 0 &&
                !scope.scopeRow.dispatchTerminalStatus
              "
              type="text"
              size="small"
              sort="primary"
              @click="handleBindingCar(scope.scopeRow)"
              >绑定车辆</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      width="1000"
      :header-text="headerText"
      :disabled="disabled"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <div class="contentPopup">
        <div class="left">
          <el-form
            ref="Form"
            :model="pointForm"
            :rules="rules"
            label-width="140px"
            label-position="right"
            class="demo-form dialog-form"
          >
            <!-- <el-form-item label="城市代码：" prop="cityCode">
              <el-input
                v-model="pointForm.cityCode"
                :readonly="headerText !== '添加站点'"
                :disabled="disabled"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="城市名称：" prop="cityName">
              <el-cascader
                ref="cascader"
                v-model="pointForm.cityCodes"
                :disabled="disabled"
                :options="cityList"
                :props="props"
                @change="handleCascaderCity"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="公交公司：" prop="companyName">
              <companySelect
                :disabled="disabled"
                :company-tree="companyList"
                :valuename="companyName"
                :value="busCompanyId"
                @getValue="getAddGroupId"
                @getName="getName"
              ></companySelect>
            </el-form-item>
            <el-form-item label="市中心地址：" prop="busCompanyAddress">
              <el-input
                v-model="pointForm.busCompanyAddress"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="是否开通公交出行：">
              <el-switch
                v-model="pointForm.cityBusStatus"
                :disabled="disabled"
                active-value="1"
                inactive-value="0"
                active-color="#336FFE"
                inactive-color="#E3E3E3"
                @click.stop.native
              ></el-switch>
            </el-form-item>
            <el-form-item label="是否开通乘车码：">
              <el-switch
                v-model="pointForm.trainCodeStatus"
                :disabled="disabled || pointForm.cityBusStatus == 0"
                active-color="#336FFE"
                inactive-color="#E3E3E3"
                @click.stop.native
              ></el-switch>
            </el-form-item>
            <el-form-item label="是否配置调度终端：">
              <el-switch
                v-model="pointForm.dispatchTerminalStatus"
                :disabled="disabled || pointForm.cityBusStatus == 0"
                active-color="#336FFE"
                inactive-color="#E3E3E3"
                @click.stop.native
              ></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div v-show="disabled" class="mapMask"></div>
          <div v-show="!disabled" class="searchBox">
            <i class="iconfont its_gy_sousuo"></i>
            <el-input
              id="tipinput"
              v-model="searchInput"
              clearable
              type="text"
              placeholder="输入搜索地址"
            ></el-input>
            <div class="searchContent">
              <div
                v-for="(item, index) in searchList"
                :key="index"
                class="content-address"
                @click="searchClick(item)"
              >
                <span class="address">{{ item.name }}</span>
                <span class="addressDetail"
                  >{{ `${item.pname}${item.cityname}${item.address}` }}
                </span>
              </div>
            </div>
          </div>
          <div id="map" class="map"></div>
        </div>
      </div>
    </charterPopup>
    <bindingCarDialog ref="bindingCarDialog"></bindingCarDialog>
  </div>
</template>

<script>
import { getRegionParents } from "@/tools/tools.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import bindingCarDialog from "./bindingCarDialog.vue";
import {
  addBusTravelConfigurationAPI,
  busTravelConfigurationCompanyTreeAPI,
  busTravelConfigurationListAPI,
  busTravelConfigurationSelectOneAPI,
  busTravelConfigurationUpdateAPI,
  busTravelConfigurationSortingAPI,
  getCityCodeAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
    bindingCarDialog,
  },
  data() {
    return {
      searchList: [], //地图的搜索结果
      searchInput: "",
      disabled: false,
      companyName: null, //公交公司名字
      busCompanyId: null, //公交公司ID
      companyList: [], //公交公司tree
      cityList: [], //城市列表
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      pointForm: {
        cityCodes: [], //城市代码
        cityName: [], //城市名
        companyName: null, //公交公司名字
        busCompanyId: null, //公交公司ID
        busCompanyAddress: "", //市中心地址
        latitude: "", //纬度
        longitude: "", //经度
        cityBusStatus: "0",
        trainCodeStatus: false,
        dispatchTerminalStatus: false,
      },
      rules: {
        // cityCode: [
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //       if (value === "") {
        //         callback("请输入城市代码");
        //       } else if (/[^\d]/.test(value)) {
        //         callback("请输入数字");
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
        cityName: [
          { required: true, message: "请填写城市名称", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请选择公交公司", trigger: "change" },
        ],
        busCompanyAddress: [
          { required: true, message: "请选择市中心地址", trigger: "blur" },
        ],
      },
      show: false,
      headerText: "",
      tableData: [],
      titleName: [
        // {
        //   title: "城市代码",
        //   props: "cityCode",
        // },
        {
          title: "城市名称",
          props: "cityName",
          render: (h, params, index) => {
            return h("span", params.cityName.join(","));
          },
        },
        {
          title: "公交公司",
          props: "companyName",
        },
        {
          title: "是否开通公交出行",
          props: "cityBusStatus",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.cityBusStatus,
                "active-color": "#336FFE",
                "inactive-color": "#E3E3E3",
                "active-value": "1",
                "inactive-value": "0",
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    cityBusStatus: val,
                  };
                  if (val == 0) {
                    obj.trainCodeStatus = false;
                    obj.dispatchTerminalStatus = false;
                  }
                  this.rentalTableDataUpdateFun(obj);
                },
              },
            });
          },
        },
        {
          title: "是否开通乘车码",
          props: "trainCodeStatus",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.trainCodeStatus,
                "active-color": "#336FFE",
                "inactive-color": "#E3E3E3",
                disabled: params.cityBusStatus == 0,
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    trainCodeStatus: val == 1 ? true : false,
                  };
                  this.rentalTableDataUpdateFun(obj);
                },
              },
            });
          },
        },
        {
          title: "是否配备调度终端",
          props: "dispatchTerminalStatus",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.dispatchTerminalStatus,
                "active-color": "#336FFE",
                "inactive-color": "#E3E3E3",
                disabled: params.cityBusStatus == 0,
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    dispatchTerminalStatus: val == 1 ? true : false,
                  };
                  this.rentalTableDataUpdateFun(obj);
                },
              },
            });
          },
        },
      ],
      map: null,
      address: "四川",
    };
  },
  created() {
    this.getCompanyTree();
  },
  mounted() {
    this.renderData();
    this.getCityCode();
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) this.searchFun(val);
      },
    },
    "pointForm.cityBusStatus"(val) {
      if (val == 0) {
        this.pointForm.trainCodeStatus = false;
        this.pointForm.dispatchTerminalStatus = false;
      }
    },
  },
  methods: {
    //表格拖拽
    dragFinsh(val) {
      let params = val.reduce((arr, cur) => {
        let obj = {};
        obj.id = cur.id;
        obj.serialNumber = cur.serialNumber;
        arr.push(obj);
        return arr;
      }, []);
      busTravelConfigurationSortingAPI(params).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
        }
      });
    },
    // 点击编辑/查看按钮
    updateBtn(obj, STATUSBTN) {
      let arr = getRegionParents(obj.cityCode[0]);
      busTravelConfigurationSelectOneAPI({ id: obj.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.show = true;
          this.headerText = STATUSBTN;
          if (STATUSBTN == "编辑") {
            this.$nextTick(() => {
              this.address = this.$refs.cascader.getCheckedNodes()[0].label;
            });
          } else if (STATUSBTN == "查看") {
            this.disabled = true;
          }
          this.pointForm = {
            ...res.data,
            // cityBusStatus: res.data.cityBusStatus == 0 ? false : true,
            // trainCodeStatus: res.data.trainCodeStatus == 0 ? false : true,
          };
          this.$nextTick(() => {
            this.companyName = res.data.companyName;
            this.busCompanyId = res.data.busCompanyId;
            this.initMap();
            this.map.setCenter([
              res.data.longitude || undefined,
              res.data.latitude || undefined,
            ]);
          });
        }
      });
    },
    /**
     * @description 绑定车辆
     */
    handleBindingCar(obj) {
      this.$refs.bindingCarDialog.openDialog(obj);
    },
    // 修改公交出行配置
    rentalTableDataUpdateFun(obj) {
      obj.$index = undefined;
      obj.DataLength = undefined;
      busTravelConfigurationUpdateAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
          this.closePopupFun();
        }
      });
    },
    // 表格数据渲染
    renderData() {
      busTravelConfigurationListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 获取公交公司
    getCompanyTree() {
      busTravelConfigurationCompanyTreeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.companyList = res.data;
        }
      });
    },
    //获取城市代码
    getCityCode() {
      getCityCodeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.cityList = this.getTreeData(res.data[0].districts);
        }
      });
    },
    getTreeData(data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].districts.length < 1) {
          data[index].districts = undefined;
        } else {
          this.getTreeData(data[index].districts);
        }
      }
      return data;
    },
    //选择城市
    handleCascaderCity(val) {
      const node = this.$refs.cascader.getCheckedNodes();
      this.pointForm.cityName = node[0].pathLabels;
      let center = node[0].data.center.split(",");
      this.map.setCenter([center[0], center[1]]);
    },
    //地图搜索输入框
    searchClick(item) {
      this.map.setCenter([item.location.lng, item.location.lat]);
      this.map.setZoom(16);
      this.pointForm.busCompanyAddress = item.address;
      this.pointForm.latitude = item.location.lat;
      this.pointForm.longitude = item.location.lng;
      this.searchList = [];
      this.searchInput = "";
    },
    searchFun(val) {
      let vm = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        var autoOptions = {
          city: vm.address,
          extensions: "all",
          citylimit: true, //是否强制在设置的城市内搜索
          autoFitView: true,
          children: 1,
        };
        var placeSearch = new AMap.PlaceSearch(autoOptions);
        placeSearch.search(val, function (status, result) {
          console.log(result);
          // 搜索成功时，result即是对应的匹配数据
          if (result.info == "OK") {
            vm.searchList = result.poiList.pois;
          }
        });
      });
    },
    // 实例化地图
    initMap() {
      this.map = null;
      this.map = new AMap.Map("map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.positionPicker();
    },
    // 拖拽选址方法
    positionPicker() {
      let vm = this;
      // 地图拖动的时候清空文本标记Marker Text
      this.map.on("dragging", function () {
        vm.map.clearMap();
      });
      this.map.on("zoomchange", function () {
        vm.map.clearMap();
      });
      // 地图拖拽
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          zIndex: 666,
          iconStyle: {
            //自定义外观
            url: require("@/assets/images/home/from_icon2.png"),
            ancher: [15, 30],
            size: [30, 39],
          },
        });
        vm.pickerPosition.on("success", function (positionResult) {
          if (positionResult.regeocode.pois.length > 0) {
            vm.pointForm.busCompanyAddress =
              positionResult.regeocode.pois[0]?.name;
            vm.pointForm.latitude =
              positionResult.regeocode.pois[0].location.lat;
            vm.pointForm.longitude =
              positionResult.regeocode.pois[0].location.lng;
          }
          vm.map.clearMap();
          vm.marker = new AMap.Text({
            text: positionResult.regeocode.pois[0].name,
            zIndex: 777,
            anchor: "center",
            offset: new AMap.Pixel(0, -60),
            style: {
              "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
              padding: "14px",
            },
            position: [
              positionResult.position.lng,
              positionResult.position.lat,
            ],
          });
          vm.marker.setMap(vm.map);
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    // 点击添加
    addBtnFun() {
      this.show = true;
      this.headerText = "添加站点";
      this.$nextTick(() => {
        this.initMap();
      });
    },
    // 取消按钮回调
    closePopupFun() {
      this.show = false;
      this.disabled = false;
      this.companyName = null; //公交公司名字
      this.busCompanyId = null; //公交公司ID
      this.pointForm = {
        // cityCode: "", //城市代码
        cityName: "", //城市名
        companyName: null, //公交公司名字
        busCompanyId: null, //公交公司ID
        busCompanyAddress: "", //市中心地址
        latitude: "", //纬度
        longitude: "", //经度
        cityBusStatus: "0",
        trainCodeStatus: false,
        dispatchTerminalStatus: false,
      };
    },
    // 确定按钮回调
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let obj = this.deepClone(this.pointForm);
          obj.cityCode = [obj.cityCodes[obj.cityCodes.length - 1]];
          // obj.cityBusStatus = obj.cityBusStatus == true ? 1 : 0;
          // obj.trainCodeStatus = obj.trainCodeStatus == true ? 1 : 0;
          if (this.headerText == "添加站点") {
            addBusTravelConfigurationAPI(obj).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.closePopupFun();
              }
            });
          } else if (this.headerText == "编辑") {
            this.rentalTableDataUpdateFun(obj);
          }
        }
      });
    },
    // 公交公司emit
    getName(val) {
      this.pointForm.companyName = val;
      this.companyName = val;
    },
    // 公交公司回显
    getAddGroupId(v) {
      if (v == null) {
        this.pointForm.busCompanyId = "";
        this.busCompanyId = "";
      } else {
        this.pointForm.busCompanyId = v;
        this.busCompanyId = v;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.bus-travel-config-page {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .bus-travel-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .Table {
    margin-top: 20px;
  }
  .mapMask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .searchBox {
    position: absolute;
    top: 10px;
    right: 30px;
    width: 230px !important;
    height: 28px !important;
    z-index: 2056;
    /deep/.el-input {
      input {
        padding-left: 30px;
      }
    }
    i {
      position: absolute;
      font-size: 20px;
      z-index: 2057;
      top: 4px;
      left: 6px;
    }
    .searchContent {
      max-height: 320px;
      overflow-x: hidden;
      overflow-y: auto;
      background: #ffffff;
    }
    .address {
      width: calc(100% - 8px);
      display: inline-block;
      cursor: pointer;
      padding: 4px 4px 0 4px;
      line-height: 24px;
    }
    .addressDetail {
      width: calc(100% - 8px);
      display: inline-block;
      border-bottom: 1px solid #f4f4f4;
      cursor: pointer;
      padding: 0 4px 4px 4px;
      line-height: 24px;
      font-size: 12px;
      color: #afafaf;
    }
  }
  /deep/.el-textarea__inner {
    //el_input中的隐藏属性
    resize: none; //主要是这个样式
    height: 100px;
  }
  .contentPopup {
    display: flex;
    .dialog-form {
      padding: 0;
    }
    .left {
      width: 40%;
    }
    .right {
      width: 60%;
      padding-right: 20px;
      position: relative;
      .map {
        width: 100%;
        height: 360px;
      }
    }
  }
}
</style>
