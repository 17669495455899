<template>
  <div class="binding-car-dialog">
    <el-dialog
      title="绑定车辆"
      :visible.sync="dialogVisible"
      width="1120px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      @close="changeClose"
    >
      <div class="binding-car-title">
        <div>
          <span class="label">城市代码：</span>
          <span v-if="bindingCarObj.cityCode" class="text">{{
            bindingCarObj.cityCode.join(",")
          }}</span>
        </div>
        <div>
          <span class="label">城市名称：</span>
          <span v-if="bindingCarObj.cityName" class="text">{{
            bindingCarObj.cityName.join(",")
          }}</span>
        </div>
        <div>
          <span class="label">公交公司：</span>
          <span class="text">{{ bindingCarObj.companyName }}</span>
        </div>
      </div>
      <div class="binding-car-main">
        <el-tabs
          v-if="tabsList.length"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            v-for="(item, index) in tabsList"
            :key="index"
            :label="item.name"
            :name="item.id + ''"
          >
            <div class="tip">绑定的车辆</div>
            <div class="content">
              <div
                v-for="(ele, index) in buttonList"
                :key="index"
                class="button"
              >
                <div class="disabled-box">
                  <span>{{ ele.cph }}</span>
                  <img
                    src="../../../assets/images/home/cancel.png"
                    mode="scaleToFill"
                    @click="deleteLineBindCarInfo(ele.id, item.id)"
                  />
                </div>
              </div>
              <div class="car-input">
                <el-autocomplete
                  v-model.trim="cph"
                  :fetch-suggestions="querySearchAsync"
                  :trigger-on-focus="false"
                  clearable
                  placeholder="请输入车牌号"
                  @select="handleAutocomplete"
                ></el-autocomplete>
                <div class="binding-car-button">
                  <img
                    style="margin: 0 12px"
                    src="../../../assets/images/home/confirm.png"
                    mode="scaleToFill"
                    @click="confirmCph(item.id, item.name)"
                  />
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div v-else style="text-align: center">暂无线路</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectLineBindCarInfoAPI,
  getLineBindCarInfoAPI,
  addLineBindCarInfoAPI,
  deleteLineBindCarInfoAPI,
  queryItsListAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      cph: "",
      cphObj: {},
      bindingCarObj: {},
      dialogVisible: false,
      activeName: null,
      tabsList: [],
      buttonList: [],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    openDialog(obj) {
      this.bindingCarObj = obj;
      this.dialogVisible = true;
      this.getSelectLineBindCarInfo();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    changeClose() {
      this.cph = "";
      this.cphObj = {};
    },
    /**
     * @description 查询公司绑定线路
     */
    getSelectLineBindCarInfo() {
      let params = {
        city: this.bindingCarObj.cityCode.join(","),
        parentId: this.bindingCarObj.busCompanyId,
      };
      selectLineBindCarInfoAPI(params).then((res) => {
        if (res.code === "SUCCESS") {
          this.tabsList = res.data;
          this.activeName = res.data[0]?.id.toString();
          res.data.length && this.getLineBindCarInfo(res.data[0]?.id);
        }
      });
    },
    /**
     * @description 绑定的车辆-车牌号模糊查询
     */
    querySearchAsync(queryString, cb) {
      if (queryString != "" && queryString != " ") {
        let cphArray = [];
        queryItsListAPI({ cph: queryString }).then((res) => {
          if (res.code == "SUCCESS") {
            res.data.map((item) => {
              cphArray.push({ value: item.cph, ...item });
            });
            cb(cphArray);
          }
        });
      }
    },
    handleAutocomplete(item) {
      this.cphObj = item;
    },
    /**
     * @description 获取线路下所绑定车辆信息
     */
    getLineBindCarInfo(companyId) {
      getLineBindCarInfoAPI({ companyId }).then((res) => {
        if (res.code === "SUCCESS") {
          this.buttonList = res.data;
        }
      });
    },
    /**
     * @description 添加绑定车辆信息
     */
    confirmCph(lineId, lineName) {
      if (!this.cph) {
        this.$message.error("请输入车牌号");
        return;
      }
      let params = {
        cph: this.cph,
        parentId: this.cphObj.companyId, //公司ID
        actualLineId: this.cphObj.companyId, //实际its车辆公司ID
        parentName: this.cphObj.companyName, //公司名称
        engineNo: this.cphObj.engineNo, //发动机号
        vehicleNo: this.cphObj.vehicleNo, //车架号
        id: this.cphObj.id, //车辆ID
        companyId: lineId, //线路ID
        companyName: lineName, //线路名称
        isItsBind: false, //是否是调度终端
      };
      addLineBindCarInfoAPI(params).then((res) => {
        if (res.code === "SUCCESS" && res.data) {
          this.$message.success("绑定成功");
          this.getLineBindCarInfo(lineId);
          this.cph = "";
        }
      });
    },
    /**
     * @description 删除线路车辆绑定信息
     */
    deleteLineBindCarInfo(id, companyId) {
      deleteLineBindCarInfoAPI({ id }).then((res) => {
        if (res.code === "SUCCESS" && res.data) {
          this.$message.success("删除成功");
          this.getLineBindCarInfo(companyId);
        }
      });
    },
    //切换tab项
    handleClick(tab) {
      this.cph = "";
      this.getLineBindCarInfo(tab.name);
    },
  },
};
</script>

<style lang='scss' scoped>
.binding-car-dialog {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 24px;
  }
  .binding-car-title {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    margin-bottom: 40px;
    .label {
      color: rgba(0, 0, 0, 0.45);
    }
    .name {
      color: #333333;
    }
  }
  ::v-deep .binding-car-main {
    .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__item {
        text-align: center;
        // min-width: 88px;
        // padding: 0;
      }
    }
    ::v-deep .el-tabs__active-bar {
      // min-width: 88px;
    }
    .el-tabs__content {
      background: #f7f8f9;
      opacity: 1;
      padding: 20px 24px;
      min-height: 380px;
    }
    .tip {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #323233;
      margin-bottom: 16px;
    }
    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .button {
        display: flex;
        align-items: center;
        margin: 0 32px 16px 0;
        .disabled-box {
          box-sizing: border-box;
          width: 160px;
          height: 32px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
        }
      }
      .car-input {
        display: flex;
        align-items: center;
        margin: 0 32px 16px 0;
        .el-select {
          width: 160px;
        }
      }
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
